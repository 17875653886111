// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-radio-tsx": () => import("./../../../src/pages/radio.tsx" /* webpackChunkName: "component---src-pages-radio-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */),
  "component---src-pages-store-cart-tsx": () => import("./../../../src/pages/store/cart.tsx" /* webpackChunkName: "component---src-pages-store-cart-tsx" */),
  "component---src-pages-store-thank-you-tsx": () => import("./../../../src/pages/store/thank-you.tsx" /* webpackChunkName: "component---src-pages-store-thank-you-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-templates-artist-tsx": () => import("./../../../src/templates/artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */),
  "component---src-templates-radio-tsx": () => import("./../../../src/templates/radio.tsx" /* webpackChunkName: "component---src-templates-radio-tsx" */),
  "component---src-templates-release-tsx": () => import("./../../../src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */),
  "component---src-templates-store-product-tsx": () => import("./../../../src/templates/Store/product.tsx" /* webpackChunkName: "component---src-templates-store-product-tsx" */)
}

