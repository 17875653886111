import { useContext } from 'react';
import Client from 'shopify-buy';
import reduce from 'lodash/reduce';

import { StoreContext, IStoreContext } from '.';

const SHOPIFY_STORAGE_ID = 'shopify_checkout_id';

export const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
});

export const createNewCheckout = (
  store: IStoreContext['store']
): Promise<Client.Cart> => {
  return store.client.checkout.create();
};

export const fetchCheckout = (
  id: string,
  store: IStoreContext['store']
): Promise<Client.Cart> => {
  return store.client.checkout.fetch(id);
};

interface ICheckoutStorageApi {
  get: () => string | undefined;
  set: (id: string) => void;
  delete: () => void;
}

export const checkoutStorageApi: ICheckoutStorageApi = {
  get() {
    return localStorage.getItem(SHOPIFY_STORAGE_ID);
  },
  set(id) {
    return localStorage.setItem(SHOPIFY_STORAGE_ID, id);
  },
  delete() {
    return localStorage.removeItem(SHOPIFY_STORAGE_ID);
  },
};

export const useStoreCartSize = (): number => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return total;
};
