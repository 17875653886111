import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faChevronLeft,
  faBars,
  faEnvelope,
  faTimes,
  faTag,
  faChevronDown,
  faChevronUp,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faChevronLeft,
  faBars,
  faEnvelope,
  faTimes,
  faTag,
  faChevronUp,
  faChevronDown,
  faShoppingCart
);
