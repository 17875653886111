import 'tachyons/css/tachyons.min.css';
import './src/styles/global.css';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Fixes icons not adhering to size when SSR

import React from 'react';

import { StoreContextProvider } from './src/context/StoreContext';
import './src/utils/icons';

export const wrapPageElement = ({ element }) => {
  return <StoreContextProvider>{element}</StoreContextProvider>;
};
