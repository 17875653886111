import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import Client from 'shopify-buy';

import { STORE_CART_ROUTE } from 'utils/routes';
import { isBrowser } from 'utils/environment';
import {
  client,
  checkoutStorageApi,
  fetchCheckout,
  createNewCheckout,
  useStoreCartSize,
} from './utils';

export { useStoreCartSize };

export interface IStoreContext {
  store: {
    client: Client.Client;
    adding: boolean;
    checkout?: Client.Cart;
    products?: Client.Product[];
    shop: Client.ShopResource;
  };
  addVariantToCart?: (variantId: string, quantity: string) => Promise<void>;
  removeLineItem?: (
    client: Client.Client,
    checkoutID: Client.Cart.id,
    lineItemID: string
  ) => Promise<void>;
  updateLineItem?: (
    client: Client.Client,
    checkoutID: Client.Cart.id,
    lineItemID: string,
    quantity: string
  ) => Promise<void>;
  addDiscount?: (discountCode: string) => Promise<void>;
  removeDiscount?: () => Promise<void>;
}

const initialStoreState = {
  client,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
} as IStoreContext['store'];

export const StoreContext = React.createContext<IStoreContext>({
  store: initialStoreState,
});

export const StoreContextProvider: React.FC = ({ children }) => {
  const [store, updateStore] = useState<IStoreContext['store']>(
    initialStoreState as IStoreContext['store']
  );
  const isRemovedRef = useRef(false);

  const setCheckoutInState = (checkout: Client.Cart) => {
    if (isBrowser) checkoutStorageApi.set(checkout.id as string);

    updateStore(prevState => {
      return { ...prevState, checkout };
    });
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart
      const existingCheckoutID = isBrowser ? checkoutStorageApi.get() : null;

      // If a checkout ID exists
      if (existingCheckoutID) {
        // Attempt to fetch checkout
        try {
          const checkout = await fetchCheckout(existingCheckoutID, store);

          // Make sure this cart hasn’t already been purchased
          if (!isRemovedRef.current && !checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          // Otherwise delete the checkout ID from storage
          checkoutStorageApi.delete();
        }
      }

      // If checkout ID does not exist or has been deleted, create a new checkout
      const newCheckout = await createNewCheckout(store);
      if (!isRemovedRef.current) setCheckoutInState(newCheckout);
    };

    initializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.client.checkout]);

  useEffect(
    () => () => {
      isRemovedRef.current = true;
    },
    []
  );

  return (
    <StoreContext.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          updateStore(prevState => {
            return { ...prevState, adding: true };
          });

          const { checkout, client } = store;

          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false };
              });
            })
            .then(() => {
              navigate(STORE_CART_ROUTE);
            });
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res };
              });
            });
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res };
              });
            });
        },
        addDiscount: discountCode => {
          const { checkout, client } = store;

          client.checkout
            .addDiscount(checkout.id, discountCode)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout };
              });
            });
        },
        removeDiscount: () => {
          const { checkout, client } = store;

          client.checkout.removeDiscount(checkout.id).then(checkout => {
            updateStore(prevState => {
              return { ...prevState, checkout };
            });
          });
        },
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
