export const buildPath = (base: string, path: string): string =>
  `${base}/${path}`;

export const HOMEPAGE_ROUTE = '/';
export const ARTISTS_ROUTE = '/artists';
export const ARTIST_DETAILS_ROUTE = (slug: string): string =>
  `/artists/${slug}`;
export const RELEASES_ROUTE = '/releases';
export const RELEASE_DETAILS_ROUTE = (slug: string): string =>
  `/releases/${slug}`;
export const RADIO_ROUTE = '/radio';
export const RADIO_DETAILS_ROUTE = (slug: string): string => `/radio/${slug}`;
export const ABOUT_ROUTE = '/about';

// Store
export const STORE_ROUTE = '/store';
export const STORE_CART_ROUTE = `${STORE_ROUTE}/cart`;
export const STORE_ORDER_SUCCESS_ROUTE = `${STORE_ROUTE}/thank-you`;
export const STORE_PRODUCT_ROUTE = (slug: string): string =>
  `${STORE_ROUTE}/product/${slug}`;
export const buildStoreProductRouteFromHandle = (handle: string): string =>
  STORE_PRODUCT_ROUTE(handle);
export const buildStoreProductRouteFromItem = (
  item: ShopifyBuy.LineItem
): string => STORE_PRODUCT_ROUTE(item.variant.product.handle);

// PUBLIC ROUTES
export const NAV_ITEMS = [
  { to: ARTISTS_ROUTE, title: 'Artists' },
  { to: RELEASES_ROUTE, title: 'Releases' },
  { to: RADIO_ROUTE, title: 'Radio' },
  { to: ABOUT_ROUTE, title: 'About' },
  { to: STORE_ROUTE, title: 'Store' },
];
